import { ICartTool } from '../../../interfaces/cart-tool.interface'
import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export const setCartTools =
    (cartTools: ICartTool[]) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'SET_CART_TOOLS',
            payload: cartTools,
        })
    }
