import { Button, Checkbox, FormControlLabel, Typography, Grid } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatPrice } from '../../../utils/currency'

interface IProps {
    readonly totalQuantity: number
    readonly total: number
}

const StyledMobileTotalGrid = styled(Grid)`
    display: none;
    border-top: 1px solid var(--text-disabled-color);
    position: sticky;
    bottom: 0;
    background: white;
    padding: 12px var(--center-content-padding);
    margin: 0 calc(-1 * var(--center-content-padding));
    width: 100vw;

    ${({ theme }) => theme.breakpoints.down('md')} {
        display: flex;
    }
`

const StyledCountsGrid = styled(Grid)`
    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledTotalQuantityRow = styled(StyledRow)`
    margin-bottom: 7px;
`

const StyledQuantityTypography = styled(Typography)`
    font-size: 20px;
`

const StyledTotalTypography = styled(Typography)`
    font-size: 24px;
    font-weight: 500;

    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-right: 32px;
    }
`

const StyledButton = styled(Button)`
    margin-bottom: 16px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
    margin: 0;
`

const StickyTotal: FC<IProps> = ({ totalQuantity, total }) => {
    const { t } = useTranslation()

    return (
        <StyledMobileTotalGrid container justifyContent='space-between'>
            <StyledCountsGrid item sm={3}>
                <StyledSection>
                    <StyledTotalQuantityRow>
                        <StyledQuantityTypography>
                            {totalQuantity} {t('cart:items')}
                        </StyledQuantityTypography>
                    </StyledTotalQuantityRow>
                    <StyledRow>
                        <StyledTotalTypography>{t('cart:total')}</StyledTotalTypography>
                        <StyledTotalTypography>{formatPrice(total)}</StyledTotalTypography>
                    </StyledRow>
                </StyledSection>
            </StyledCountsGrid>
            <Grid item sm={6} xs={12}>
                <StyledSection>
                    <StyledButton variant='contained' color='primary'>
                        {t('cart:place-order')}
                    </StyledButton>
                    <StyledFormControlLabel
                        control={<Checkbox name='one-delivery' color='primary' />}
                        label={t('cart:one-delivery')}
                    />
                </StyledSection>
            </Grid>
        </StyledMobileTotalGrid>
    )
}

export default StickyTotal
