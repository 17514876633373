import * as Sentry from '@sentry/gatsby'
import axios from 'axios'
import { graphql, useStaticQuery } from 'gatsby'
import { find } from 'lodash'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { API_URL } from '../constants/api'
import { CLIENT_ID_COOKIE_NAME } from '../constants/cookies'
import { CLIENT_ID_HEADER_NAME } from '../constants/headers'
import { IIdentifiable } from '../interfaces/identifiable.interface'
import { StoreToolType } from '../interfaces/store-tool.interface'

type FetchAvailabilityResultType = Array<IIdentifiable & StoreToolType>

export const useFetchStoreTools = (storeToolIds: number[]): StoreToolType[] => {
    const [storeTools, setStoreTools] = useState<StoreToolType[]>([])

    const data = useStaticQuery(graphql`
        query {
            build: externalJson {
                ...ExternalFragment
            }
        }
    `)

    const [clientIdCookies] = useCookies([CLIENT_ID_COOKIE_NAME])
    const clientId = clientIdCookies[CLIENT_ID_COOKIE_NAME]

    useEffect(() => {
        if (!storeToolIds || storeToolIds.length === 0) {
            setStoreTools([])
            return
        }

        const fetchTools = async () => {
            const stringifiedParams = queryString.stringify({ id: storeToolIds })
            try {
                const { data: availabilityResult } = await axios.get<FetchAvailabilityResultType>(
                    `${API_URL}/availability?${stringifiedParams}`,
                    { headers: { [CLIENT_ID_HEADER_NAME]: clientId } }
                )

                const storeTools = availabilityResult.map((item) => {
                    const { price, availability, ...toolRef } = item
                    const tool = find(data.build.tools, (e) => e.id === toolRef.id)
                    return { ...tool, price, availability }
                })

                setStoreTools(storeTools)
            } catch (error) {
                setStoreTools([])
                Sentry.captureException(error)
            }
        }

        fetchTools()
    }, [storeToolIds.sort().join(',')])

    return storeTools
}
