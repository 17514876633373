import React, { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

interface IProps {
    readonly initialValue: number
    readonly onChange: (value: number) => void
}

const BORDER_RADIUS = 4
const BUTTON_TEXT_COLOR = '#fff'
const DISABLED_BUTTON_TEXT_COLOR = '#ddd'
const BORDER_COLOR = '#dfdfdf'

const turnOffNativeInputButtons = css`
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

const StyledContainer = styled.div`
    ${turnOffNativeInputButtons}

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: ${BORDER_RADIUS}px;
    height: 40px;
    border: 1px solid ${BORDER_COLOR};
    box-sizing: border-box;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        height: 32px;
    }
`

const StyledButton = styled.button`
    width: 40px;
    height: 100%;
    font-size: 16px;
    background: transparent;
    color: ${BUTTON_TEXT_COLOR};
    border: none;
    text-align: center;
    color: var(--text-primary-color);
    font-weight: 900;
    cursor: pointer;
    padding: 0;

    &:disabled {
        cursor: initial;
        color: ${DISABLED_BUTTON_TEXT_COLOR};
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 30px;
    }
`

const StyledDescreaseButton = styled(StyledButton)`
    border-radius: ${BORDER_RADIUS}px 0 0 ${BORDER_RADIUS}px;
`

const StyledIncreaseButton = styled(StyledButton)`
    border-radius: 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0;
`

const StyledInput = styled.input`
    width: 53px;
    padding: 0 8px;
    box-sizing: border-box;
    font-size: 16px;
    user-select: none;
    outline: none;
    border: none;
    text-align: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 38px;
        padding: 0;
    }
`

const QuantityInput: FC<IProps> = ({ initialValue, onChange }) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        onChange(value)
    }, [value])

    const isDecreaseDisabled = value === 1

    return (
        <StyledContainer>
            <StyledDescreaseButton
                disabled={isDecreaseDisabled}
                onClick={() => !isDecreaseDisabled && setValue((prev) => Math.max(prev - 1, 1))}
            >
                &ndash;
            </StyledDescreaseButton>
            <StyledInput
                type='number'
                inputMode='numeric'
                value={value}
                onChange={(e) => setValue(Math.max(Number(e.target.value) || 1, 1))}
            />
            <StyledIncreaseButton onClick={() => setValue((prev) => prev + 1)}>
                &#xff0b;
            </StyledIncreaseButton>
        </StyledContainer>
    )
}

export default QuantityInput
