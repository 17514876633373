import { PageProps } from 'gatsby'
import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cart from '../components/Cart'
import Layout from '../components/common/Layout'
import SEO from '../components/common/SEO'
import { useFetchStoreTools } from '../hooks/use-fetch-store-tools'
import { ICartTool } from '../interfaces/cart-tool.interface'
import { setCartTools } from '../state/actions/tools/set-cart-tools'
import { AppDispatchType } from '../state/interfaces/app-dispatch.type'
import { IReduxState } from '../state/interfaces/state.interface'
import { fetchCartToolsFromLS, replaceCartToolsInLS } from '../utils/local-storage'

const CartPage: FC<PageProps> = () => {
    const reduxDispatch: AppDispatchType = useDispatch()
    const cartTools = useSelector((state: IReduxState) => state.tools.cartTools)

    useEffect(() => {
        const cartTools = fetchCartToolsFromLS()
        reduxDispatch(setCartTools(cartTools))
    }, [])

    const cartToolIds = cartTools.map(({ id }) => id)

    const storeTools = useFetchStoreTools(cartToolIds)

    if (!storeTools) {
        return <>Loading...</>
    }

    const changeCartToolQuantity = useCallback(
        (id: number, quantity: number) => {
            const newValue: ICartTool[] = cartTools.map((cartTool) =>
                cartTool.id === id ? { id, quantity } : cartTool
            )
            replaceCartToolsInLS(newValue)
            reduxDispatch(setCartTools(newValue))
        },
        [JSON.stringify(cartTools)]
    )

    return (
        <Layout>
            <SEO title='Cart' />
            <Cart storeTools={storeTools} changeCartToolQuantity={changeCartToolQuantity} />
        </Layout>
    )
}

export default CartPage
