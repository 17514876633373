import { Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { StoreToolType } from '../../../../interfaces/store-tool.interface'
import { removeCartTool } from '../../../../state/actions/tools/remove-cart-tool'
import { AppDispatchType } from '../../../../state/interfaces/app-dispatch.type'
import { IReduxState } from '../../../../state/interfaces/state.interface'
import { formatPrice } from '../../../../utils/currency'
import { removeCartToolFromLS } from '../../../../utils/local-storage'
import QuantityInput from '../QuantityInput'

interface IProps {
    readonly className?: string
    readonly storeTool: StoreToolType
    readonly changeCartToolQuantity: (id: number, quantity: number) => void
}

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledQuantityContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledMobileSubtotal = styled.div`
    display: none;
    align-items: center;
    height: 32px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: flex;
    }
`

const StyledSubtotalTypography = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
`

const StyledPieceTypography = styled(Typography)`
    color: var(--text-disabled-color);
    margin-top: 8px;
`

const StyledDeleteContainer = styled.div`
    color: var(--text-secondary-color);
    height: 40px;
    display: flex;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        height: 32px;
    }
`

const StyledDeleteIcon = styled(Delete)`
    cursor: pointer;
`

const Actions: FC<IProps> = ({ className, storeTool, changeCartToolQuantity }) => {
    const reduxDispatch: AppDispatchType = useDispatch()
    const cartTools = useSelector((state: IReduxState) => state.tools.cartTools)
    const cartTool = cartTools.find(({ id }) => id === storeTool.id)

    if (!cartTool) {
        return null
    }

    const subtotal = formatPrice(storeTool.price * cartTool.quantity)

    return (
        <StyledContainer className={className}>
            <StyledQuantityContainer>
                <QuantityInput
                    initialValue={cartTool.quantity}
                    onChange={(newValue: number) => changeCartToolQuantity(storeTool.id, newValue)}
                />
                <StyledPieceTypography>
                    {formatPrice(storeTool.price)} / apiece
                </StyledPieceTypography>
            </StyledQuantityContainer>
            <StyledMobileSubtotal>
                <StyledSubtotalTypography>{subtotal}</StyledSubtotalTypography>
            </StyledMobileSubtotal>
            <StyledDeleteContainer>
                <StyledDeleteIcon
                    onClick={() => {
                        removeCartToolFromLS(storeTool.id)
                        reduxDispatch(removeCartTool(storeTool.id))
                    }}
                />
            </StyledDeleteContainer>
        </StyledContainer>
    )
}

export default Actions
