import { Typography } from '@mui/material'
import React, { FC } from 'react'
import styled from 'styled-components'
import { IToolAvailabilityItem } from '../../../../interfaces/tool-availability-item.interface'
import CheckIcon from './assets/check.svg'

interface IProps {
    readonly availability: IToolAvailabilityItem[]
}

const StyledContainer = styled.div``

const StyledTitle = styled.div`
    margin-bottom: 8px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const StyledTitleTypography = styled(Typography)`
    color: var(--text-disabled-color);
`

const StyledQuantityTypography = styled(Typography)`
    font-size: 14px;
    margin-left: 16px;
`

const StyledCaptionTypography = styled(Typography)`
    color: var(--text-disabled-color);
    font-size: 14px;
`

const StyledList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`

const StyledItem = styled.li`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
`

interface IStyledCheckIcon {
    readonly index: number
}

const iconColors = ['#429D00', '#F2C94C', '#FF802C']

const StyledCheckIcon = styled(CheckIcon)<IStyledCheckIcon>`
    path {
        fill: ${({ index }) => iconColors[index]};
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 16px;
    }
`

const Availability: FC<IProps> = ({ availability }) => {
    return (
        <StyledContainer>
            <StyledTitle>
                <StyledTitleTypography>Delivery</StyledTitleTypography>
            </StyledTitle>
            <StyledList>
                {availability.map(({ quantity, duration }, index) => (
                    <StyledItem key={duration}>
                        <StyledCheckIcon index={index} />
                        <StyledQuantityTypography>{quantity}</StyledQuantityTypography>
                        <StyledCaptionTypography>
                            &nbsp;·&nbsp;{duration} days
                        </StyledCaptionTypography>
                    </StyledItem>
                ))}
            </StyledList>
        </StyledContainer>
    )
}

export default Availability
