import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatPrice } from '../../../utils/currency'

interface IProps {
    readonly totalQuantity: number
    readonly total: number
}

const StyledBox = styled.div`
    border: 1px solid var(--text-disabled-color);
    border-radius: 4px;
    width: 100%;
    min-height: 251px;
    padding: 16px 16px 7px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledTotalQuantityRow = styled(StyledRow)`
    margin-bottom: 8px;
`

const StyledQuantityTypography = styled(Typography)`
    font-size: 20px;
`

const StyledQuantityCountTypography = styled(Typography)`
    font-size: 20px;
`

const StyledTotalTypography = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`

const StyledButton = styled(Button)`
    margin-bottom: 12px;
`

const StyledDeliveryTypography = styled(Typography)`
    color: var(--text-disabled-color);
    margin-bottom: 7px;
`

const DesktopTotal: FC<IProps> = ({ totalQuantity, total }) => {
    const { t } = useTranslation()

    return (
        <StyledBox>
            <StyledSection>
                <StyledTotalQuantityRow>
                    <StyledQuantityTypography>{t('cart:total-quantity')}</StyledQuantityTypography>
                    <StyledQuantityCountTypography>{totalQuantity}</StyledQuantityCountTypography>
                </StyledTotalQuantityRow>
                <StyledRow>
                    <StyledTotalTypography>{t('cart:total')}</StyledTotalTypography>
                    <StyledTotalTypography>{formatPrice(total)}</StyledTotalTypography>
                </StyledRow>
            </StyledSection>
            <StyledSection>
                <StyledButton variant='contained' color='primary'>
                    {t('cart:place-order')}
                </StyledButton>
                <StyledDeliveryTypography>
                    {t('cart:delivery-date-determined-later')}
                </StyledDeliveryTypography>
                <FormControlLabel
                    control={<Checkbox name='one-delivery' color='primary' />}
                    label={t('cart:one-delivery')}
                />
            </StyledSection>
        </StyledBox>
    )
}

export default DesktopTotal
